import axios from '@/plugins/axios'

/**
 * Get advertiser
 */
const getCompany = async (id) => {
  try {
    const response = await axios.get(`admin/companies/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get companies
 */
const getCompanies = async ({
  page = 1,
  search = null,
  // eslint-disable-next-line camelcase
  agencies_type = false,
  // eslint-disable-next-line camelcase
  advertisers_type = false
}) => {
  try {
    const params = {
      page: page
    }
    if (search) params.search = search
    // eslint-disable-next-line camelcase
    if (agencies_type) params.agencies_type = 1
    // eslint-disable-next-line camelcase
    if (advertisers_type) params.advertisers_type = 1

    const response = await axios.get('admin/companies', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getCompany,
  getCompanies
}
